<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ data.id ? "Editar usuário" : "Cadastrar usuário" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Nome*"
                :disabled="enabledInputs"
                v-model="data.name"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                label="E-mail*"
                :disabled="enabledInputs"
                v-model="data.email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="funcoes"
                item-text="slug"
                item-value="id"
                v-model="data.role_id"
                :error-messages="errors.role_id"
                label="Função"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-show="checkNeedCustomer">
              <v-select
                :items="clientes"
                item-text="name"
                item-value="id"
                v-model="data.customer_id"
                :error-messages="errors.customer_id"
                label="Cliente"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Senha"
                type="password"
                :disabled="enabledInputs"
                v-model="data.password"
                :error-messages="errors.password"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Confirmar senha"
                type="password"
                :disabled="enabledInputs"
                v-model="data.password_confirmation"
                :error-messages="errors.password_confirmation"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        outlined
        class="no-text-transform"
        color="primary"
        @click="cancel()"
      >
        Voltar
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="no-text-transform" color="primary" @click="saveData()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import UserService from "@/services/User/UserService";
import ClienteService from "@/services/Cliente/ClienteService";
import FuncaoService from "@/services/Funcao/FuncaoService";

export default {
  name: "UserForm",

  mounted: async function () {
    const id = this.$route.params.id;
    this.id = id;

    if (id !== undefined) {
      this.loading = true;
      await this.showData(id);
    }
    await this.showFuncoes();
    await this.showClientes();
  },

  data: () => ({
    data: {
      permissions: {},
      role: {},
    },
    id: undefined,
    errors: {},
    listRoles: [],
    funcoes: [],
    clientes: [],
    select: {},
    loading: false,
    enabledInputs: false,
    UserService: new UserService(""),
    ClienteService: new ClienteService(""),
    FuncaoService: new FuncaoService("/roles"),
  }),

  computed: {
    checkNeedCustomer: function () {
      let role = this.funcoes.find((element) => {
        return element.id == this.data.role_id;
      });
      console.log(role);
      return role?.type == "requester" && role.id != 5;
    },
  },

  methods: {
    async saveData() {
      try {
        this.loading = true;
        this.enabledInputs = true;

        const id = this.data.id !== undefined ? "/" + this.data.id : "";

        await this.UserService.storeOrUpdate("users" + id, this.data);

        if (this.id === undefined) {
          window.auth.createUserWithEmailAndPassword(
            this.data.email,
            "wsjus-password-auth"
          );

          this.$refs.form.reset();

          return;
        }
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loading = false;
        this.enabledInputs = false;
        ToastService("Ação realizada com sucesso.", "success");
      }
    },

    async showData(id) {
      try {
        this.data = await this.UserService.show("users/" + id);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    async showFuncoes() {
      try {
        this.funcoes = await this.FuncaoService.index("");
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    async showClientes() {
      try {
        this.dataLoading = true;

        const res = await this.ClienteService.index("customers");

        this.clientes = res;
      } catch (exception) {
        ToastService(exception, "error");
      }
    },

    cancel() {
      this.$router.push({ path: "/app/usuarios" });
    },
  },
};
</script>
